import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
export default {
  methods: {
    /**
     * https://github.com/mauricius/vue-draggable-resizable
     * 表格列可拖拽
     * 表格上使用：:components="drag(columns)"
     * tips:columns中需包含dataIndex或者key和width(Number)
     */
    drag(columns) {
      return {
        header: {
          cell: (h, props, children) => {
            const { key, ...restProps } = props
          // 此处的this.columns 是定义的table的表头属性变量
            const col = this.columns.find((col) => {
              const k = col.dataIndex || col.key
              return k === key
            })
            if (!col || !col.width) {
              return h('th', { ...restProps }, [...children])
            }
            const dragProps = {
              key: col.dataIndex || col.key,
              class: 'table-draggable-handle',
              attrs: {
                w: 10,
                x: col.width,
                z: 1,
                axis: 'x',
                draggable: true,
                resizable: false
              },
              on: {
                dragging: (x, y) => {
                  col.width = Math.max(x, 1)
                }
              }
            }
            const drag = h('vue-draggable-resizable', { ...dragProps })
            return h('th', { ...restProps, class: 'resize-table-th' }, [...children, drag])
          }
        }
      }
    }
  }
}
